var searchExample = document.querySelector(".main-search-form .main-search-form__sub-title > span")
if (searchExample) {
    searchExample.addEventListener(
        "click",
        function (e) {
            document.querySelector(".main-search-form  .main-search-form__search-input").value = this.innerText
        },
        false
    )
}

document.addEventListener("DOMContentLoaded", function () {
    var navIcon = document.getElementById("nav-icon6")
    navIcon.addEventListener("click", function () {
        this.classList.toggle("open")
        document.querySelector("#header-nav").classList.toggle("open")
        document.querySelector("body").classList.toggle("open")
    })
})

